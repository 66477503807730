<template>
  <b-card v-if="coopIndicatorHveAudit">
    <b-row>
      <b-col cols="12">
        <h3>{{ coopIndicatorHveAudit.name }}</h3>
      </b-col>
      <b-col cols="6">
        <b-row class="text-center">
          <b-col cols="12">
            <vue-apex-charts
              type="radialBar"
              height="60"
              :series="[Math.floor(coopIndicatorHveAudit.passing_percent)]"
              :options="coopChartOptions"
            />
          </b-col>
          <b-col
            cols="12"
            class="h4 font-weight-normal"
          >
            {{ coopIndicatorHveAudit.pretty_passing_percent }}
          </b-col>
          <b-col
            cols="12"
            class="label"
          >
            Acquis dans ma coop
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row class="text-center">
          <b-col cols="12">
            <vue-apex-charts
              type="radialBar"
              height="60"
              :series="[Math.floor(globalIndicatorHveAudit.passing_percent)]"
              :options="globalChartOptions"
            />
          </b-col>
          <b-col
            cols="12"
            class="h4 font-weight-normal"
          >
            {{ globalIndicatorHveAudit.pretty_passing_percent }}
          </b-col>
          <b-col
            cols="12"
            class="label"
          >
            Acquis dans la communauté
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import coopHveMixins from "@/mixins/coopHve";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueApexCharts
  },
  mixins: [coopHveMixins],
  props: {
    coopIndicatorHveAudit: {
      type: Object,
      default: () => ({})
    },
    globalIndicatorHveAudit: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      radialChartOptions: {
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none"
            }
          },
          active: {
            filter: {
              type: "none"
            }
          }
        },
        grid: {
          padding: {
            top: -5,
            bottom: -5
          }
        },
        chart: {
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "30%"
            },
            dataLabels: {
              show: false
            },
            track: {
              background: "#cccccc"
            }
          }
        }
      }
    };
  },
  computed: {
    coopChartOptions() {
      return {
        ...this.fixChartsRender(this.cardPassingFarmsPercentage), // fn from coopHveMixins
        colors: [$themeColors.success]
      };
    },
    globalChartOptions() {
      return {
        ...this.fixChartsRender(this.cardPassingFarmsPercentage), // fn from coopHveMixins
        colors: [$themeColors.primary]
      };
    }
  }
};
</script>

<style  lang="scss" scoped>
.label {
  font-size: 10px;
}
</style>
