<template>
  <b-container v-if="isLoaded">
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h1>Certifications HVE de ma coopérative</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xl="4"
      >
        <b-card class="Card">
          <b-row class="h-100">
            <b-col cols="12">
              <h4>{{ coopFarmsCount }} Exploitations</h4>
            </b-col>
            <b-col cols="12">
              <vue-apex-charts
                type="donut"
                height="200"
                :series="hveChartSeries"
                :options="donutChartOptions"
              />
            </b-col>
            <b-col
              cols="12"
              align-self="end"
              class="Card__label"
            >
              <feather-icon
                icon="CheckCircleIcon"
                class="text-success"
              />
              {{ passingFarmsLabels }}
            </b-col>
            <b-col
              cols="12"
              align-self="end"
              class="Card__label"
            >
              <feather-icon
                icon="XCircleIcon"
                class="text-warning"
              />
              <b-link
                v-if="failingFarmsPercentage > 0"
                :to="{ path: 'farms', query: { scope: 'failed' }}"
              >
                {{ failingFarmsLabels }}
              </b-link>
              <template v-else>
                {{ failingFarmsLabels }}
              </template>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="4"
      >
        <HveCard
          title="Ma coopérative"
          color="success"
          :hve-audit="coopHveAudit"
          :is-coop="true"
          class="Card"
        />
      </b-col>
      <b-col
        cols="12"
        xl="4"
      >
        <HveCard
          title="Communauté Agriprogress"
          color="primary"
          :hve-audit="globalHveAudit"
          class="Card"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h1>
          Part des indicateurs validés
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(indicator, idx) in coopIndicatorsGroup"
        :key="indicator.slug"
        cols="3"
      >
        <HveIndicatorCard
          :coop-indicator-hve-audit="coopIndicatorsGroup[idx]"
          :global-indicator-hve-audit="globalIndicatorsGroup[idx]"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { $themeColors } from "@themeConfig";
import {
  BCard, BContainer, BRow, BCol, BLink
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import HveCard from "@/components/cooperative/hve/HveCard.vue";
import HveIndicatorCard from "@/components/cooperative/hve/HveIndicatorCard.vue";
import coopHveMixins from "@/mixins/coopHve";

export default {
  components: {
    BCard,
    BContainer,
    BRow,
    BCol,
    BLink,
    VueApexCharts,
    HveCard,
    HveIndicatorCard
  },
  mixins: [coopHveMixins],
  data() {
    return {
      donutChartOptions: {
        dataLabels: {
          enabled: false
        },
        labels: ["Certifiables", "Non certifiables"],
        legend: {
          show: false
        },
        colors: [$themeColors.success, $themeColors.warning],
        plotOptions: {
          pie: {
            donut: {
              size: "75%"
            }
          }
        },
        stroke: {
          show: false
        }
      },
      radialChartOptions: {
        grid: {
          padding: {
            top: -5,
            bottom: -5
          }
        },
        chart: {
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "30%"
            },
            dataLabels: {
              show: false
            },
            track: {
              background: "#cccccc"
            }
          }
        },
        stroke: {
          curve: "stepline",
          lineCap: "round"
        }
      }
    };
  },
  computed: {
    hveChartSeries() {
      return [this.coopHveAudit.passing_farms_count, this.coopHveAudit.failing_farms_count];
    },
    passingFarmsLabels() {
      return this.coopHveAudit.passing_farms_count > 1
        ? `${this.coopHveAudit.passing_farms_count} Certifiables - ${this.coopPassingFarmsPercentage}%`
        : `${this.coopHveAudit.passing_farms_count} Certifiable - ${this.coopPassingFarmsPercentage}%`;
    },
    failingFarmsPercentage() {
      return this.coopFarmsCount === 0 ? 0 : Math.floor((this.coopHveAudit.failing_farms_count * 100) / this.coopFarmsCount);
    },
    failingFarmsLabels() {
      return this.coopHveAudit.failing_farms_count > 1
        ? `${this.coopHveAudit.failing_farms_count} Non certifiables - ${this.failingFarmsPercentage}%`
        : `${this.coopHveAudit.failing_farms_count} Non certifiable - ${this.failingFarmsPercentage}%`;
    },
    coopIndicatorsGroup() {
      return this.coopHveAudit.passing_by_indicator_group;
    },
    globalIndicatorsGroup() {
      return this.globalHveAudit.passing_by_indicator_group;
    },
    isLoaded() {
      return this.coopHveAudit && this.globalHveAudit;
    }
  }
};
</script>

<style lang="scss" scoped>
  .Card {
    height: 95%;

    &__label {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
</style>
