import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("cooperative", { coopHveAudit: "hveAudit" }),
    ...mapState("global", { globalHveAudit: "hveAudit" }),
    coopFarmsCount() {
      return this.coopHveAudit.passing_farms_count + this.coopHveAudit.failing_farms_count;
    },
    coopPassingFarmsPercentage() {
      return this.coopFarmsCount === 0 ? 0 : Math.floor((this.coopHveAudit.passing_farms_count * 100) / this.coopFarmsCount);
    },
    globalFarmsCount() {
      return this.globalHveAudit.passing_farms_count + this.globalHveAudit.failing_farms_count;
    },
    globalPassingFarmsPercentage() {
      return this.globalFarmsCount === 0 ? 0 : Math.floor((this.globalHveAudit.passing_farms_count * 100) / this.globalFarmsCount);
    },
    cardPassingFarmsPercentage() {
      return this.isCoop ? this.coopPassingFarmsPercentage : this.globalPassingFarmsPercentage;
    }

  },
  methods: {
    fixChartsRender(value) {
      // Return charts options object with relevant style
      return value === 0
        ? {
          ...this.radialChartOptions,
          stroke: {
            lineCap: "butt"
          }
        }
        : {
          ...this.radialChartOptions,
          stroke: {
            lineCap: "round"
          }
        };
    }
  }
};
