<template>
  <b-card v-if="hveAudit">
    <b-row class="h-100">
      <b-col cols="12">
        <h4>{{ title }}</h4>
      </b-col>
      <b-col cols="12">
        <b-row class="text-center">
          <b-col cols="12">
            <vue-apex-charts
              type="radialBar"
              height="80"
              :series="[cardPassingFarmsPercentage]"
              :options="farmsChartOptions"
            />
          </b-col>
          <b-col
            cols="12"
            class="h1"
          >
            {{ cardPassingFarmsPercentage }}%
          </b-col>
          <b-col cols="12">
            Certifiables HVE
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row class="text-center">
          <b-col cols="12">
            <vue-apex-charts
              type="radialBar"
              height="60"
              :series="[optionAPassingFarmsPercentage]"
              :options="optionAChartOptions"
            />
          </b-col>
          <b-col
            cols="12"
            class="h1"
          >
            {{ optionAPassingFarmsPercentage }}%
          </b-col>
          <b-col cols="12">
            <b-link
              v-if="optionAPassingFarmsPercentage > 0 && isCoop"
              :to="{ path: 'farms', query: { scope: 'option_a_passed' }}"
            >
              Option A
            </b-link>
            <template v-else>
              Option A
            </template>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row class="text-center">
          <b-col cols="12">
            <vue-apex-charts
              type="radialBar"
              height="60"
              :series="[optionBPassingFarmsPercentage]"
              :options="optionBChartOptions"
            />
          </b-col>
          <b-col
            cols="12"
            class="h1"
          >
            {{ optionBPassingFarmsPercentage }}%
          </b-col>
          <b-col cols="12">
            <b-link
              v-if="optionBPassingFarmsPercentage > 0 && isCoop"
              :to="{ path: 'farms', query: { scope: 'option_b_passed' }}"
            >
              Option B
            </b-link>
            <template v-else>
              Option B
            </template>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BLink
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import coopHveMixins from "@/mixins/coopHve";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    VueApexCharts
  },
  mixins: [coopHveMixins],
  props: {
    title: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    },
    hveAudit: {
      type: Object,
      default: () => ({})
    },
    isCoop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radialChartOptions: {
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none"
            }
          },
          active: {
            filter: {
              type: "none"
            }
          }
        },
        colors: [$themeColors[this.color]],
        grid: {
          padding: {
            top: -5,
            bottom: -5
          }
        },
        chart: {
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "30%"
            },
            dataLabels: {
              show: false
            },
            track: {
              background: "#cccccc"
            }
          }
        }
      }
    };
  },
  computed: {
    farmsChartOptions() {
      return this.fixChartsRender(this.cardPassingFarmsPercentage); // fn from coopHveMixins
    },
    optionAPassingFarmsPercentage() {
      return this.hveAudit.passing_farms_count === 0
        ? 0 : Math.floor((this.hveAudit.option_a_passing_farms_count * 100) / this.hveAudit.passing_farms_count);
    },
    optionAChartOptions() {
      return this.fixChartsRender(this.optionAPassingFarmsPercentage);
    },
    optionBPassingFarmsPercentage() {
      return this.hveAudit.passing_farms_count === 0 ? 0 : Math.floor((this.hveAudit.option_b_passing_farms_count * 100) / this.hveAudit.passing_farms_count);
    },
    optionBChartOptions() {
      return this.fixChartsRender(this.optionBPassingFarmsPercentage);
    }

  }
};
</script>
